* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  background-color: #d3d3d3;
}

#root {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: initial;
}

input,
select,
button,
textarea {
  font-size: inherit;
}
