@mixin clickable($bg, $bg-hover) {
  background-color: $bg;
  transition: background-color $transition-duration;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $bg-hover;
    border-color: $bg-hover;
  }
}

@mixin max-small-screen() {
  @media screen and (max-width: 899px) {
    @content;
  }
}

@mixin min-small-screen() {
  @media screen and (min-width: 900px) {
    @content;
  }
}
