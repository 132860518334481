button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 18px;
  border: none;
  padding: 0 8px;
  height: $input-height;

  @include clickable($blue-dark, $blue-darker);

  > :first-child.icon {
    margin-right: 6px;
  }

  > :last-child.icon {
    margin-left: 6px;
  }
}
