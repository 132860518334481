.layout {
  .top-bar {
    background-color: $top-bar-color;
    height: $top-bar-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 36px;
      margin-left: 16px;
      color: $top-bar-text-color;
    }

    .icon {
      margin-right: 16px;
      color: $top-bar-text-color;
    }
  }

  main {
    padding-left: 8px;
    padding-right: 8px;
  }
}
