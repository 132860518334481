.tools-page {
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;

    .active-tool {
      flex: 0 0 400px;
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;

      .name {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .search-wrapper {
      width: 100%;

      .search {
        margin-bottom: 8px;
        font-size: 1.5rem;
        height: 1.5 * $input-height;
        padding: 0 8px;
        width: 100%;
      }
    }

    .results {
      display: flex;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .result {
        margin: 8px;
        padding: 8px;
        border-radius: $border-radius;

        @include clickable(gray, dimgray);
      }
    }
  }
}
