.tool.two-panels {
  display: flex;
  flex-direction: column;

  .input-output {
    flex: 1;
    display: flex;

    @include max-small-screen {
      flex-direction: column;
    }

    > * {
      flex: auto;

      &:first-child {
        @include min-small-screen {
          margin-right: 16px;
        }

        @include max-small-screen {
          margin-bottom: 16px;
        }
      }
    }
  }

  .tool-options {
    margin-top: 16px;
  }
}
