$border-radius: 4px;
$transition-duration: 0.1s;
$input-height: 32px;

// Theme colors.
$blue-lighter: #e5f9ff;
$blue-light: #b7f3ff;
$blue-dark: #0070b9;
$blue-darker: #003d7d;

// Top bar.
$top-bar-color: #222;
$top-bar-text-color: #eee;
$top-bar-height: 48px;
