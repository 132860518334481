.tool {
  width: 100%;
  flex: auto;

  textarea {
    font-family: monospace;
    resize: none;

    &.error {
      border: 2px solid red;
    }
  }
}
